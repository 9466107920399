import {observer} from "mobx-react";
import React from 'react';
import {RouterProvider} from "react-router-dom";
import {index} from "src/pages";


function App() {

    return (
        <div className="main-container">
            <RouterProvider router={index}/>
        </div>
    );
}

export default observer(App);
