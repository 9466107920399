import {observable} from "mobx";
import {createContext, useContext} from "react";

export class RootStore {
    @observable userUUID: string

    constructor() {
        this.userUUID = ""
    }
}

export const rootStore = new RootStore()

export const RootStoreContext = createContext<RootStore>(rootStore);
export const StoreProvider = RootStoreContext.Provider;

export const useStore = (): RootStore => useContext(RootStoreContext);