import React from 'react';
import {createBrowserRouter,} from "react-router-dom";
import {AppPage} from "src/pages/AppPage";

export const index = createBrowserRouter([
    {
        path: "/",
        element: <AppPage/>,
    },
    {
        path: "*",
        element: <AppPage/>,
    }
])