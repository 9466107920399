import React from "react";

export function AppPage() {
    React.useEffect(() => {

    })

    return (
        <div className="logo-holder">
            <div className="logo">
                <span className="logo-1">braiN</span><span className="logo-2">Surge</span>
            </div>
            <div className="sub-logo">
                ideas &amp; problem solving
            </div>
        </div>
    );
}